import Vue from 'vue';

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },
  components: {
    'selection-list': () => import('@/features/marketplace-filters-sidebar/SelectionList.vue'),
  },
  computed: {
    filtersModel: {
      get() {
        return this.filters;
      },
      set(val) {
        this.$emit('update:filters', val);
      },
    },
  },
  methods: {
    toggleFilter(filterKey) {
      Vue.set(this.filtersModel, filterKey, !this.filters[filterKey]);
    },
  },
};
