<template>
  <v-list-group :value="true">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="black--text font-weight-bold my-0 py-3">
          Chains
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item class="py-5 light-blue lighten-5 d-block">
      <v-select
        :items="chainOptions"
        filled
        label="Chains"
        solo
        flat
        outlined
        hide-details
        class="items-select font-weight-bold mb-4"
      />
    </v-list-item>
  </v-list-group>
</template>

<script>
import filtersComponentMixin from './mixins/filtersComponent';

export default {
  name: 'ChainFilters',
  components: {
  },
  data: () => ({
    search: null,
    chainOptions: [
      {
        text: 'Binance',
        value: 'binance',
      },
    ],
    isLoading: false,
  }),
  mixins: [filtersComponentMixin],
  computed: {
  },
  watch: {
    search() {
      // Some fetch call
      // e.g fetch('https;//api.coingecko.com/api/v3/coins/list')
      // this.collections = response
      // catch (err) {}
    },
  },
};
</script>
